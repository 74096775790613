import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import * as React from "react";
import { useAuthToken } from "../hooks/useAuthToken";
import { useConstructUrl } from "../hooks/useConstructUrl";

export type User = {
  id: string;
  username: string;
  is_admin: boolean;
};

type UserContextProps = {
  userId: string;
  username: string | null;
  isAdmin: boolean | null;
  setUsername: (username: string) => void;
  setIsAdmin: (isAdmin: boolean) => void;
  logout: () => void;
  login: (token: string, user: User) => void;
};

const UserContext = React.createContext<UserContextProps>(null as any);

export const UserContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [username, setUsername] = React.useState<string | null>(null);
  const [isAdmin, setIsAdmin] = React.useState<boolean | null>(null);
  const { token, setToken } = useAuthToken();

  const constructUrl = useConstructUrl();
  const logout = React.useCallback(() => {
    setUsername("");
    setIsAdmin(false);
    setToken(null);
  }, [setUsername, setIsAdmin, setToken]);

  const { data: user, error } = useQuery<any, any, any>({
    queryKey: ["user"],
    queryFn: async () => {
      const response = await axios.get(constructUrl("/api/user"));
      return response.data;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!token,
  });

  React.useEffect(() => {
    if (user) {
      setUsername(user.username);
      setIsAdmin(user.is_admin);
    }
  }, [user, setIsAdmin, setUsername]);

  React.useEffect(() => {
    if (error && error.response.status === 401) {
      logout();
    }
  }, [error, logout]);

  const login = React.useCallback(
    (token: string, user: User) => {
      setToken(token);
      setUsername(user.username);
      setIsAdmin(!!user.is_admin);
    },
    [setToken]
  );

  const value = {
    userId: user ? user.id : undefined,
    username,
    setUsername,
    isAdmin,
    setIsAdmin,
    logout,
    login,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContextProps => {
  const contextValue = React.useContext(UserContext);
  if (contextValue) {
    return contextValue;
  } else {
    throw new Error("missing UserContext.Provider");
  }
};
