import * as React from "react";
import Coin from "../../models/coin";
import { useHeaderContext } from "../../contexts/HeaderContext";
import { Box } from "@mui/material";

const Found: React.FC<{ coin: Coin }> = ({ coin }) => {
  const { setTitle } = useHeaderContext();

  React.useEffect(() => {
    setTitle("Sorry");
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 1,
      }}
    >
      <Box sx={{ padding: 1 }}>This coins has already been found</Box>
    </Box>
  );
};

export default Found;
