import React, { PropsWithChildren, useEffect, useRef, useState } from "react";

interface MapProps extends google.maps.MapOptions {
  style?: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  center: { lat: number; lng: number };
}

const CoinMap: React.FC<MapProps & PropsWithChildren> = ({
  onClick,
  onIdle,
  children,
  style,
  center,
  ...options
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          zoom: 16,
          center,
          mapTypeId: "satellite",
        })
      );
    }
  }, [ref, map, center]);

  return (
    <>
      <div
        ref={ref}
        style={{ width: "100%", height: "100%", borderRadius: "10px" }}
      />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

export default CoinMap;
