import * as React from "react";

type HeaderContextProps = {
  title: string | null;
  setTitle: (title: string) => void;
};

const HeaderContext = React.createContext<HeaderContextProps>(null as any);

export const HeaderContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [title, setTitle] = React.useState<string | null>(null);

  const value = {
    title,
    setTitle,
  };

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};

export const useHeaderContext = (): HeaderContextProps => {
  const contextValue = React.useContext(HeaderContext);
  if (contextValue) {
    return contextValue;
  } else {
    throw new Error("missing HeaderContext.Provider");
  }
};
