import * as React from "react";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import DescriptionIcon from "@mui/icons-material/Description";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BoltIcon from "@mui/icons-material/Bolt";
import QrCodeIcon from "@mui/icons-material/QrCode";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { GeoPoint } from "firebase/firestore";
import { useParams } from "react-router-dom";
import Coin from "../../../models/coin";
import CoinMap from "./components/CoinMap";
import Marker from "./components/Marker";
import Code from "../Code";
import { useUserContext } from "../../../contexts/UserContext";

const convertJson = (coin: any) => {
  return {
    ...coin,
    location: new GeoPoint(coin.location._latitude, coin.location._longitude),
  };
};

const LocationPage = () => {
  const { id } = useParams();
  const { userId } = useUserContext();
  const [selectedNav, setSelectedNav] = React.useState<number>(0);

  const { data: coin, isLoading } = useQuery<Coin>({
    queryKey: ["coin", "location", id],
    queryFn: async () => {
      const response = await axios.get(`/api/coin/location/${id}`);
      return convertJson(response.data);
    },
  });

  const render = (status: Status) => {
    return <h1>{status}</h1>;
  };

  if (isLoading) {
    return <Skeleton variant="text" sx={{ fontSize: "1rem" }} />;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          height: "calc(100% - 95px)",
        }}
      >
        {selectedNav === 0 && (
          <Typography sx={{ padding: 1 }}>{coin?.description}</Typography>
        )}
        {selectedNav === 1 && (
          <Wrapper
            apiKey={"AIzaSyDrfz0CbvlwOnLh2PehO3kzmOPIUVn8z_w"}
            render={render}
          >
            <CoinMap
              onClick={() => alert("click")}
              center={{
                lat: coin?.location.latitude as number,
                lng: coin?.location.longitude as number,
              }}
            >
              <Marker
                clickable={true}
                position={{
                  lat: coin?.location.latitude as number,
                  lng: coin?.location.longitude as number,
                }}
              />
            </CoinMap>
          </Wrapper>
        )}
        {selectedNav === 3 && <Code />}
      </Box>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={selectedNav}
          onChange={(_, value) => setSelectedNav(value)}
        >
          <BottomNavigationAction
            label="Description"
            icon={<DescriptionIcon />}
          />
          <BottomNavigationAction label="Map" icon={<LocationOnIcon />} />
          <BottomNavigationAction label="Virality" icon={<BoltIcon />} />
          {userId && coin && coin?.user?.indexOf(userId) !== -1 && (
            <BottomNavigationAction label="Code" icon={<QrCodeIcon />} />
          )}
        </BottomNavigation>
      </Paper>
    </>
  );
};

export default LocationPage;
