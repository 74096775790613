import DeleteIcon from "@mui/icons-material/Delete";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useRef, useState } from "react";
import ListLoadingSkeleton from "../../components/ListLoadingSkeleton";
import { useHeaderContext } from "../../contexts/HeaderContext";
import { User } from "../../contexts/UserContext";
import { useConstructUrl } from "../../hooks/useConstructUrl";
import { withGatedRoute } from "../withGatedRoute";
import ConfirmDialog from "../../components/ConfirmDialog";

const AllWrapped: React.FC = () => {
  const constructUrl = useConstructUrl();
  const { setTitle } = useHeaderContext();
  const selectedUserId = useRef<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  React.useEffect(() => {
    setTitle("All Users");
  });

  const {
    data: users,
    isLoading,
    refetch,
  } = useQuery<Array<User>>({
    queryKey: ["users", "all"],
    queryFn: async () => {
      const response = await axios.get(constructUrl("/api/users/all"));
      return response.data;
    },
  });

  const mutation = useMutation<any, any, any>({
    mutationKey: ["users", "all"],
    mutationFn: (id) => {
      return axios.delete(constructUrl(`/api/user/${id}`));
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 1,
      }}
    >
      {!isLoading && users?.length === 0 && (
        <Box sx={{ padding: 1 }}>There are no users</Box>
      )}
      {isLoading && <ListLoadingSkeleton />}
      {!isLoading && (
        <List>
          {users
            ?.sort((a, b) => {
              if (a.username < b.username) {
                return -1;
              }
              if (a.username > b.username) {
                return 1;
              }
              return 0;
            })
            ?.map((user, index) => (
              <React.Fragment key={`user-${index}`}>
                <ListItem
                  disablePadding
                  secondaryAction={
                    user.is_admin ? (
                      <IconButton>
                        <SupervisorAccountIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => {
                          selectedUserId.current = user.id;
                          setIsOpen(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )
                  }
                >
                  <ListItemButton>
                    <ListItemText primary={user.username} />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
        </List>
      )}
      <ConfirmDialog
        body="This operation cannot be undone, so delete wisely."
        title="Delete User?"
        onClose={() => setIsOpen(false)}
        open={isOpen}
        onSelect={(value) => {
          if (value === "ok") {
            mutation.mutate(selectedUserId.current, {
              onSuccess: () => {
                refetch();
              },
            });
          }
          setIsOpen(false);
        }}
      />
    </Box>
  );
};

export default withGatedRoute(AllWrapped, true);
