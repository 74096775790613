import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import { withGatedRoute } from "../withGatedRoute";

const CoinValidationPageWrapped: React.FC = () => {
  const { id, code } = useParams();

  const { data } = useQuery<any>({
    queryKey: ["coin", "validate", id],
    queryFn: async () => {
      const response = await axios.post(`/api/coin/validate/${id}/${code}`);
      return response.data;
    },
  });

  return (
    <div style={{ padding: "10px" }}>
      <Typography variant="h1">{data?.virality}</Typography>
    </div>
  );
};

export default withGatedRoute(CoinValidationPageWrapped);
