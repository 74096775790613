import { Box, Button, FormControl, Link, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Formik } from "formik";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useHeaderContext } from "../contexts/HeaderContext";
import { useUserContext } from "../contexts/UserContext";

type SignupFormValues = {
  [value: string]: string;
  email: string;
  password: string;
  confirmPassword: string;
};

const Signup = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { login } = useUserContext();
  const { setTitle } = useHeaderContext();

  React.useEffect(() => {
    setTitle("Signup");
  });

  const mutation = useMutation<any, any, any>({
    mutationKey: ["coin"],
    mutationFn: (data) => {
      return axios.post("/auth/signup", data);
    },
  });

  const code = React.useMemo(() => {
    return searchParams.get("code");
  }, [searchParams]);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        confirmPassword: "",
      }}
      onSubmit={(values, actions) => {
        mutation.mutate(
          {
            email: values.email,
            password: values.password,
          },
          {
            onSuccess(response) {
              const { token, user } = response.data;
              login(token, user);
              if (code) {
                navigate(`/coin/${code}`);
              } else {
                navigate("/coins");
              }
            },
            onError(error, variables) {
              const errors = {} as SignupFormValues;
              error.response.data.forEach((error: any) => {
                console.log(error);
                errors[error.path] = error.msg;
              });
              console.log(errors);
              actions.setErrors(errors);
              actions.setSubmitting(false);
            },
          }
        );
      }}
      validate={(values) => {
        const errors = {} as SignupFormValues;
        if (!values.email) {
          errors.email = "Required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = "Email address is invalid";
        }
        if (values.password.length < 2) {
          errors.password = "Password needs to be at least 8 characters long";
        }
        if (
          values.password &&
          values.confirmPassword &&
          values.password !== values.confirmPassword
        ) {
          errors.confirmPassword = "Passwords don't match";
        }
        return errors;
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            padding: 1,
          }}
          onSubmit={handleSubmit}
        >
          <FormControl
            fullWidth
            variant="filled"
            sx={{ padding: 1, boxSizing: "border-box" }}
          >
            <TextField
              placeholder="Email Address"
              autoFocus
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              name="email"
              error={!!touched.email && !!errors.email}
              helperText={errors.email}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="filled"
            sx={{ padding: 1, boxSizing: "border-box" }}
          >
            <TextField
              placeholder="Password"
              type="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              name="password"
              error={!!touched.password && !!errors.password}
              helperText={errors.password}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="filled"
            sx={{ padding: 1, boxSizing: "border-box" }}
          >
            <TextField
              placeholder="Confirm Password"
              type="password"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              name="confirmPassword"
              error={!!touched.confirmPassword && !!errors.confirmPassword}
              helperText={errors.confirmPassword}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="filled"
            sx={{ padding: 1, boxSizing: "border-box" }}
          >
            <Button variant="contained" type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </FormControl>
          <Box sx={{ padding: 1, textAlign: "center" }}>
            <Link sx={{ cursor: "pointer" }}>Sign Up</Link> |{" "}
            <Link sx={{ cursor: "pointer" }}>Forgot Password</Link>
          </Box>
        </Box>
      )}
    </Formik>
  );
};

export default Signup;
