import { Box, Button, FormControl, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Formik } from "formik";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useHeaderContext } from "../contexts/HeaderContext";
import { useUserContext } from "../contexts/UserContext";

type LoginFormValues = {
  [value: string]: string;
  email: string;
  password: string;
  confirmPassword: string;
};

const Login = () => {
  const navigate = useNavigate();
  const { setTitle } = useHeaderContext();

  React.useEffect(() => {
    setTitle("Login");
  });

  const { login } = useUserContext();

  const mutation = useMutation<any, any, any>({
    mutationKey: ["coin"],
    mutationFn: (data) => {
      return axios.post("/auth/login", data);
    },
  });

  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
      }}
      onSubmit={(values, { setErrors, setSubmitting }) => {
        mutation.mutate(
          {
            username: values.username,
            password: values.password,
          },
          {
            onSuccess(response) {
              const { token, user } = response.data;
              login(token, user);
              navigate("/coins");
            },
            onError() {
              setErrors({
                username: "Invalid username or password",
              });
              setSubmitting(false);
            },
          }
        );
      }}
      validate={(values) => {
        const errors = {} as LoginFormValues;
        if (!values.username) {
          errors.username = "Email is required";
        }
        if (!values.password) {
          errors.password = "Password is required";
        }
        return errors;
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            padding: 1,
          }}
          onSubmit={handleSubmit}
        >
          <FormControl
            fullWidth
            variant="filled"
            sx={{ padding: 1, boxSizing: "border-box" }}
          >
            <TextField
              placeholder="Email Address"
              autoFocus
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              name="username"
              error={!!touched.username && !!errors.username}
              helperText={!!touched.username && errors.username}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="filled"
            sx={{ padding: 1, boxSizing: "border-box" }}
          >
            <TextField
              placeholder="Password"
              type="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              name="password"
              error={!!touched.password && !!errors.password}
              helperText={!!touched.password && errors.password}
            />
          </FormControl>
          <FormControl
            fullWidth
            variant="filled"
            sx={{ padding: 1, boxSizing: "border-box" }}
          >
            <Button variant="contained" type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </FormControl>
          <Box sx={{ padding: 1, textAlign: "center" }}>
            <Link to="/signup">Sign Up</Link> |{" "}
            <Link to="/forgot">Forgot Password</Link>
          </Box>
        </Box>
      )}
    </Formik>
  );
};

export default Login;
