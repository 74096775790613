import { Box } from "@mui/material";
import notp from "notp";
import QRCode from "qrcode";
import * as React from "react";

export type CodeProps = {
  secret?: string;
  coinId?: string;
  onTick?: (value: number) => void;
  count?: number;
  width?: number;
};

export const Code: React.FC<CodeProps> = ({
  secret,
  coinId,
  onTick,
  count = 30,
  width = 300,
}) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [code, setCode] = React.useState<string>();
  const [timer, setTimer] = React.useState<number>(count);

  const generate = React.useCallback(() => {
    if (secret) {
      return notp.totp.gen(secret);
    }
  }, [secret]);

  React.useEffect(() => {
    const interval = window.setInterval(() => {
      onTick?.(timer);
      if (timer === 1) {
        setCode(generate());
      }
      setTimer((value) => {
        if (value === 0) {
          return count;
        }
        return value - 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timer, generate, onTick, count]);

  React.useEffect(() => {
    QRCode.toCanvas(
      canvasRef.current,
      `https://viralcoins.co/coin/validate/${coinId}/${code}`,
      {
        width,
        margin: 0,
      }
    );
  }, [code, coinId, width]);

  React.useEffect(() => {
    setCode(generate());
  }, [generate]);

  return (
    <Box
      sx={{
        width: width,
        height: width,
        backgroundColor: "#787878",
      }}
    >
      <canvas ref={canvasRef} />
    </Box>
  );
};
