import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog";
import ListLoadingSkeleton from "../../components/ListLoadingSkeleton";
import { useHeaderContext } from "../../contexts/HeaderContext";
import { useConstructUrl } from "../../hooks/useConstructUrl";
import Coin from "../../models/coin";
import { withGatedRoute } from "../withGatedRoute";
import { QrCode } from "@mui/icons-material";

const AllWrapped: React.FC = () => {
  const navigate = useNavigate();
  const constructUrl = useConstructUrl();
  const { setTitle } = useHeaderContext();
  const selectedCoinId = useRef<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  React.useEffect(() => {
    setTitle("All Coins");
  });

  const {
    data: coins,
    isLoading,
    refetch,
  } = useQuery<Array<Coin>>({
    queryKey: ["coins", "all"],
    queryFn: async () => {
      const response = await axios.get(constructUrl("/api/coins/all"));
      return response.data;
    },
  });

  const mutation = useMutation<any, any, any>({
    mutationKey: ["coins", "all"],
    mutationFn: (id) => {
      return axios.delete(constructUrl(`/api/coin/${id}`));
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 1,
      }}
    >
      {!isLoading && coins?.length === 0 && (
        <Box sx={{ padding: 1 }}>You have no coins</Box>
      )}
      {isLoading && <ListLoadingSkeleton />}
      {!isLoading && (
        <List>
          {coins?.map((coin, index) => (
            <React.Fragment key={`coin-${index}`}>
              <ListItem
                disablePadding
                secondaryAction={
                  <>
                    <IconButton
                      onClick={() => {
                        navigate(`/coin/code/${coin.id}`);
                      }}
                    >
                      <QrCode />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        selectedCoinId.current = coin.id;
                        setIsOpen(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                }
              >
                <ListItemButton
                  onClick={() => {
                    navigate(`/coin/location/${coin.id}`);
                  }}
                >
                  <ListItemText primary={coin.code} />
                </ListItemButton>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      )}
      <ConfirmDialog
        body="This operation cannot be undone, so delete wisely."
        title="Delete Coin?"
        onClose={() => setIsOpen(false)}
        open={isOpen}
        onSelect={(value) => {
          if (value === "ok") {
            mutation.mutate(selectedCoinId.current, {
              onSuccess: () => {
                refetch();
              },
            });
          }
          setIsOpen(false);
        }}
      />
    </Box>
  );
};

export default withGatedRoute(AllWrapped, true);
