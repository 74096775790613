import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Route, Routes, useSearchParams } from "react-router-dom";
import styles from "./App.module.scss";
import { Header } from "./components/Header";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Signup from "./pages/Signup";
import CoinsAll from "./pages/coin/All";
import CoinCode from "./pages/coin/Code";
import CoinMain from "./pages/coin/Main";
import MyCoins from "./pages/coin/My";
import CoinValidation from "./pages/coin/Validate";
import CoinLocation from "./pages/coin/location/Location";
import UsersAll from "./pages/user/All";

const App = () => {
  return (
    <div
      style={{ height: "100%", display: "flex", flexDirection: "column" }}
      className={styles.root}
    >
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/coins" element={<MyCoins />} />
        <Route path="/coin/:code" element={<CoinMain />} />
        <Route path="/coin/validate/:id/:code" element={<CoinValidation />} />
        <Route path="/coin/code/:id" element={<CoinCode />} />
        <Route path="/coin/location/:id" element={<CoinLocation />} />
        <Route path="/auth/google/callback" element={<GoogleAuth />} />
        <Route path="/coins/all" element={<CoinsAll />} />
        <Route path="/users/all" element={<UsersAll />} />
      </Routes>
    </div>
  );
};

const GoogleAuth = () => {
  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");
  const scope = searchParams.get("scope");

  const { data } = useQuery<any>({
    queryKey: ["auth"],
    queryFn: async () => {
      const response = await axios.get(
        "/api/auth/google?code=" + code + "&scope=" + scope
      );
      return response.data;
    },
    enabled: !!code && !!scope,
  });

  return <>{data && JSON.stringify(data)}</>;
};

export default App;
