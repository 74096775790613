import { Skeleton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import * as React from "react";
import { useParams } from "react-router-dom";
import Coin from "../../models/coin";
import { useUserContext } from "../../contexts/UserContext";
import Add from "./Add";
import Accept from "./Accept";
import Found from "./Found";

const CoinMain = () => {
  const { code } = useParams();
  const { isAdmin } = useUserContext();

  const { data: coin, isLoading } = useQuery<Coin>({
    queryKey: ["coin", code],
    queryFn: async () => {
      const response = await axios.get(`/api/coin/found/${code}`);
      return response.data;
    },
  });

  if (isLoading) {
    return <Skeleton variant="text" sx={{ fontSize: "1rem" }} />;
  }

  if (!coin) {
    return isAdmin ? (
      <Add />
    ) : (
      <div style={{ padding: "10px" }}>This coin does not exist</div>
    );
  }

  return coin?.found ? <Found coin={coin} /> : <Accept coin={coin} />;
};

export default CoinMain;
