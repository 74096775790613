import { Box, Card, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import QRCode from "qrcode";
import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import ListLoadingSkeleton from "../../components/ListLoadingSkeleton";
import { useHeaderContext } from "../../contexts/HeaderContext";
import { useAuthToken } from "../../hooks/useAuthToken";
import Coin from "../../models/coin";
import { withGatedRoute } from "../withGatedRoute";

const CoinsPage = () => {
  const { token } = useAuthToken();
  // const navigate = useNavigate();
  const { setTitle } = useHeaderContext();

  useEffect(() => {
    setTitle("My Coins");
  });

  const { data: coins, isLoading } = useQuery<Array<Coin>>({
    queryKey: ["coins"],
    queryFn: async () => {
      const response = await axios.get("/api/user/coins?token=" + token);
      return response.data;
    },
    enabled: token !== null,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 1,
      }}
    >
      {isLoading && <ListLoadingSkeleton />}
      {!isLoading && coins?.length === 0 && (
        <Box sx={{ padding: 1 }}>You have no coins</Box>
      )}
      {!isLoading && (
        <Box
          sx={{ gap: 2, display: "flex", flexDirection: "column", padding: 1 }}
        >
          {coins?.map((coin, index) => (
            <Card
              sx={{ display: "flex", backgroundColor: "#f5f5f5" }}
              key={`coin-${index}`}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 1,
                  backgroundColor: "#ffffff",
                }}
              >
                {coin && coin.code && <CoinCode code={coin.code} />}
              </Box>
              <Box sx={{ padding: 1, width: "100%" }}>
                <Typography
                  sx={{
                    margin: "auto",
                    textAlign: "center",
                    height: "100%",
                    lineHeight: "100px",
                    fontSize: "4em",
                  }}
                >
                  {currencyFormat(coin?.virality ?? 0)}
                </Typography>
              </Box>
            </Card>
            // <React.Fragment key={`coin-${index}`}>
            //   <ListItem
            //     disablePadding
            //     secondaryAction={<LittleGraph values={coin.viralities} />}
            //   >
            //     <ListItemButton
            //       onClick={() => {
            //         navigate(`/coin/code/${coin.id}`);
            //       }}
            //     >
            //       <ListItemText primary={coin.code} />
            //     </ListItemButton>
            //   </ListItem>
            // </React.Fragment>
          ))}
        </Box>
      )}
    </Box>
  );
};

const CoinCode: React.FC<{ code: string }> = ({ code }) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  React.useEffect(() => {
    QRCode.toCanvas(canvasRef.current, `https://viralcoins.co/coin/${code}`, {
      width: 100,
      margin: 0,
    });
  }, [code]);

  return <canvas ref={canvasRef} />;
};

const currencyFormat = (num: number) => {
  return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

// const LittleGraph: React.FC<{ values?: Array<number> }> = ({ values }) => {
//   if (!values) {
//     return null;
//   }

//   const maxValue = Math.max(...values);
//   const barWidth = 10;
//   const graphWidth = barWidth * values.length + (values.length - 1);

//   return (
//     <svg width={graphWidth} height={20}>
//       {values.map((value, index) => {
//         const barHeight = (value / maxValue) * 20;
//         const x = index * (barWidth + 1);
//         const y = 20 - barHeight;
//         return (
//           <rect
//             key={`bar-${index}`}
//             x={x}
//             y={y}
//             width={barWidth}
//             height={barHeight}
//             fill="#0d98ba"
//           />
//         );
//       })}
//     </svg>
//   );
// };

export default withGatedRoute(CoinsPage);
