import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuthToken } from "../hooks/useAuthToken";
import { useUserContext } from "../contexts/UserContext";
import { Box } from "@mui/material";

export const withGatedRoute = <P extends object>(
  Component: React.ComponentType<P>,
  isUserAdmin: boolean = false
) => {
  const WithGatedComponent = (props: P) => {
    const { token } = useAuthToken();
    const { isAdmin } = useUserContext();
    const navigate = useNavigate();

    useEffect(() => {
      if (!token) {
        navigate("/login");
      }
    }, [navigate, token, isAdmin]);

    if (token && isUserAdmin && isAdmin !== null && isAdmin !== isUserAdmin) {
      return (
        <Box sx={{ padding: 1 }}>
          You don't have permission to view this page.
        </Box>
      );
    }
    return token && <Component {...props} />;
  };

  return WithGatedComponent;
};
