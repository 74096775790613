import { Box, Button, FormControl, Skeleton, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { GeoPoint } from "firebase/firestore";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useHeaderContext } from "../../contexts/HeaderContext";
import Coin from "../../models/coin";
import { withGatedRoute } from "../withGatedRoute";

const AddWrapped = () => {
  const { code } = useParams();
  const [description, setDescription] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { setTitle } = useHeaderContext();

  React.useEffect(() => {
    setTitle("Add Coin");
  });

  const mutation = useMutation<{}, Coin, Coin>({
    mutationKey: ["coin"],
    mutationFn: (data) => {
      return axios.post("/api/coin", data);
    },
  });

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        mutation.mutate(
          {
            code,
            location: new GeoPoint(latitude, longitude),
            found: false,
            description,
          },
          {
            onSuccess: (data) => {
              setIsLoading(false);
              navigate("/coins/all");
            },
          }
        );
      });
    } else {
      console.log("GeoLocation no supported");
    }
  };

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        padding: 1,
      }}
      onSubmit={onSubmit}
    >
      <FormControl
        fullWidth
        variant="filled"
        sx={{ padding: 1, boxSizing: "border-box" }}
      >
        <TextField
          multiline
          sx={{ width: "100%" }}
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
          label="Where is it?"
          InputProps={{
            style: { height: "100%", boxSizing: "border-box" },
          }}
        />
      </FormControl>
      <FormControl
        fullWidth
        variant="filled"
        sx={{ padding: 1, boxSizing: "border-box" }}
      >
        <Button variant="contained" sx={{ width: "100%" }} type="submit">
          Submit
        </Button>
      </FormControl>
    </Box>
  );
};

export default withGatedRoute(AddWrapped);
