import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export type ConfirmDialogProps = {
  open: boolean;
  body: React.ReactNode;
  onClose: () => void;
  onSelect: (value: "ok" | "cancel") => void;
  title: string;
};

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title,
  body,
  open,
  onClose,
  onSelect,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSelect("ok")}>OK</Button>
        <Button onClick={() => onSelect("cancel")} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
