import { Skeleton } from "@mui/material";

const ListLoadingSkeleton: React.FC = () => {
  return (
    <div style={{ display: "flex", gap: "5px", flexDirection: "column" }}>
      <Skeleton variant="rounded" animation="wave" height={50} />
      <Skeleton variant="rounded" animation="wave" height={50} />
      <Skeleton variant="rounded" animation="wave" height={50} />
    </div>
  );
};

export default ListLoadingSkeleton;
