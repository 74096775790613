import Cookies from "universal-cookie";

export const useAuthToken = () => {
  const cookies = new Cookies();

  const setToken = (token: string | null) => {
    const cookies = new Cookies();
    if (!token) {
      cookies.remove("token", { path: "/" });
    } else {
      cookies.set("token", token, { path: "/" });
    }
  };

  return {
    token: cookies.get("token"),
    setToken,
  };
};
