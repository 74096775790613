import { AppBar, Menu, MenuItem, Toolbar } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../contexts/UserContext";
import { useHeaderContext } from "../contexts/HeaderContext";
import React from "react";

export const Header = () => {
  const { username, logout, isAdmin } = useUserContext();
  const [adminAnchorEl, setAdminAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const [accountAnchorEl, setAccountAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { title } = useHeaderContext();

  const adminOpen = Boolean(adminAnchorEl);
  const accountOpen = Boolean(accountAnchorEl);

  const handleClickAdmin = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAdminAnchorEl(event.currentTarget);
  };

  const handleClickAccount = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAccountAnchorEl(event.currentTarget);
  };

  const handleCloseAdmin = () => {
    setAdminAnchorEl(null);
  };

  const handleCloseAccount = () => {
    setAccountAnchorEl(null);
  };

  const navigateAndClose = (url: string) => {
    navigate(url);
    handleCloseAdmin();
    handleCloseAccount();
  };

  const onClickLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        {!username && (
          <Button color="inherit" onClick={() => navigate("/login")}>
            Login
          </Button>
        )}
        {username && (
          <>
            {isAdmin && (
              <>
                <Button color="inherit" onClick={handleClickAdmin}>
                  Admin
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={adminAnchorEl}
                  open={adminOpen}
                  onClose={handleCloseAdmin}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={() => navigateAndClose("/coins/all")}>
                    All Coins
                  </MenuItem>
                  <MenuItem onClick={() => navigateAndClose("/users/all")}>
                    All Users
                  </MenuItem>
                </Menu>
              </>
            )}
            <>
              <Button color="inherit" onClick={handleClickAccount}>
                Account
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={accountAnchorEl}
                open={accountOpen}
                onClose={handleCloseAccount}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => navigateAndClose("/coins")}>
                  My Coins
                </MenuItem>
                <MenuItem onClick={onClickLogout}>Logout</MenuItem>
              </Menu>
            </>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};
