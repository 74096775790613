import Fireworks, { FireworksHandlers } from "@fireworks-js/react";
import { Box, Button, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import * as React from "react";
import { useConstructUrl } from "../../hooks/useConstructUrl";
import Coin from "../../models/coin";
import { useNavigate, useParams } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import { useHeaderContext } from "../../contexts/HeaderContext";

type AcceptProps = {
  coin: Coin;
};

const Accept: React.FC<AcceptProps> = ({ coin }) => {
  const { username } = useUserContext();
  const { code } = useParams();
  const constructUrl = useConstructUrl();
  const ref = React.useRef<FireworksHandlers>(null);
  const [showFireworks, setShowFireworks] = React.useState(true);
  const navigate = useNavigate();

  const { setTitle } = useHeaderContext();

  React.useEffect(() => {
    setTitle("Accept");
  });

  React.useEffect(() => {
    const timeout = window.setTimeout(async () => {
      await ref.current?.waitStop();
      setShowFireworks(false);
    }, 2000);
    return () => window.clearTimeout(timeout);
  }, []);

  const mutation = useMutation<{}, {}, Coin>({
    mutationKey: ["coin"],
    mutationFn: (coin) => {
      return axios.post(constructUrl(`/api/coin/accept/${coin?.code}`));
    },
  });

  const onClickAccept = () => {
    mutation.mutate(coin, {
      onSuccess: () => {
        navigate("/coins");
      },
    });
  };

  const onClickSignup = () => {
    navigate(`/signup?code=${code}`);
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "10px",
      }}
    >
      <Typography sx={{ padding: 1 }}>Congratulations!</Typography>
      <Box sx={{ padding: 1 }}>You found a coin!</Box>
      {!showFireworks &&
        (username ? (
          <Button variant="contained" onClick={onClickAccept}>
            Accept Coin
          </Button>
        ) : (
          <Button variant="contained" onClick={onClickSignup}>
            Sign up to Accept Coin
          </Button>
        ))}
      {showFireworks && (
        <Fireworks
          ref={ref}
          options={{ opacity: 0.5 }}
          style={{
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            position: "fixed",
            background: "none",
          }}
        />
      )}
    </Box>
  );
};

export default Accept;
