import { useEffect } from "react";
import { useHeaderContext } from "../contexts/HeaderContext";

const Home = () => {
  const { setTitle } = useHeaderContext();

  useEffect(() => {
    setTitle("Home");
  });

  return (
    <div style={{ padding: "10px" }}>
      <b>Welcome to ViralCoins: Unveil the Magic of Viral Treasure Hunt!</b>
      <br />
      <br />
      Are you prepared to dive into an extraordinary treasure hunt experience?
      With ViralCoins, the real world transforms into your personal playground
      for uncovering hidden treasures, in the form of unique coins. These coins
      hold more than just monetary value – they unlock thrilling adventures and,
      most significantly, alluring cash prizes!
      <br />
      <br />
      Envision yourself wandering through streets, exploring parks, and
      unveiling concealed marvels with every step you take. Our groundbreaking
      web app merges the excitement of real-world exploration with the virtual
      realm. Each ViralCoin you encounter isn't merely a piece of currency –
      it's a portal to an immersive online escapade.
      <br />
      <br />
      Each and every ViralCoin boasts a distinctive QR code that's eagerly
      awaiting your scan. By scanning this code, you claim ownership of the coin
      and set its viral journey into motion. But here's the twist: it's about
      more than just collecting coins for your own benefit. It's about
      propelling them into the realm of virality! Share the thrill with friends,
      family, and fellow explorers by encouraging them to scan the unique QR
      codes that the app generates.
      <br />
      <br />
      And that's not the end of the excitement – the thrill escalates with our
      weekly competitions! We're firm believers in celebrating the power of
      virality, and we want to reward you for it. The more people you entice to
      scan your coin's QR code, the greater the likelihood of your coin winning
      the sought-after cash prizes. It's a strategic showdown, a test of
      ingenuity, and a showcase of networking prowess – all woven into an
      exhilarating rivalry.
      <br />
      <br />
      Become a part of the ViralCoins community today and embark on an
      unforgettable journey where tangible discovery intersects with digital
      virality. Explore, share, and emerge victorious – all within your reach.
      Embark on your coin-hunting escapade today and unleash the viral marvel
      with ViralCoins!
    </div>
  );
};

export default Home;
