import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Skeleton,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import * as React from "react";
import { useParams } from "react-router-dom";
import { useHeaderContext } from "../../contexts/HeaderContext";
import { useUserContext } from "../../contexts/UserContext";
import { useConstructUrl } from "../../hooks/useConstructUrl";
import Coin from "../../models/coin";
import { withGatedRoute } from "../withGatedRoute";
import styles from "./Code.module.scss";
import { Code } from "./components/Code";

const REFRESH_TIMER_SECONDS = 30; //Refresh QR code in seconds

const CoinPageWrapped = () => {
  const { id } = useParams();
  const { isAdmin } = useUserContext();
  const constructUrl = useConstructUrl();
  const { setTitle } = useHeaderContext();
  const [timer, setTimer] = React.useState<number>(0);

  React.useEffect(() => {
    setTitle("Coin");
  });

  const {
    data: coin,
    isLoading,
    error,
  } = useQuery<Coin>({
    queryKey: ["coin", id],
    queryFn: async () => {
      const response = await axios.get(constructUrl(`/api/coin/${id}`));
      return response.data;
    },
    retry: false,
  });

  if (isLoading) {
    return <Skeleton variant="text" sx={{ fontSize: "1rem" }} />;
  }

  if (error) {
    return <Box sx={{ padding: 1 }}>Invalid coin</Box>;
  }

  return (
    <div className={styles.root}>
      <div className={styles.centered}>
        {coin && coin.id && coin.secret && (
          <Code
            coinId={coin?.id}
            secret={coin?.secret}
            onTick={(value) => setTimer(value)}
            count={REFRESH_TIMER_SECONDS}
          />
        )}
      </div>
      <div className={styles.centered}>
        <CircularProgressWithLabel variant="determinate" value={timer} />
      </div>
      {isAdmin && (
        <div className={styles.centered}>
          {/* <Link to={`/coin/validate/${coin?.id}/${code}`}>Validate link</Link> */}
        </div>
      )}
    </div>
  );
};

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  const updated = (props.value / REFRESH_TIMER_SECONDS) * 100;
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" value={updated} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${props.value}`}</Typography>
      </Box>
    </Box>
  );
}

export default withGatedRoute(CoinPageWrapped);
